/* eslint-disable max-len, no-param-reassign, no-unused-vars */
/**
 * Air theme JavaScript.
 */

// Import modules
import reframe from 'reframe.js';
import { styleExternalLinks, initExternalLinkLabels } from './modules/external-link';
import initAnchors from './modules/anchors';
import initA11ySkipLink from './modules/a11y-skip-link';
import 'slick-carousel/slick/slick';

// Define Javascript is active by changing the body class
document.body.classList.remove('no-js');
document.body.classList.add('js');

document.addEventListener('DOMContentLoaded', () => {
  initAnchors();
  styleExternalLinks();
  initExternalLinkLabels();
  initA11ySkipLink();

  var header = $(".site-header");
  var body = $('body');
  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 5) {
      header.addClass("shrink");
      body.addClass("fixedheader");
    } else {
      header.removeClass("shrink");
      body.removeClass("fixedheader");
    }
  });

  $('.sub-menu').each(function() {
    $(this).find('li:first').hover(function() {
      $(this).parents('.sub-menu').addClass('hovered');
    }, function() {
      $(this).parents('.sub-menu').removeClass('hovered');
    });
  });

  $('.navbar-toggler').on('click', function ( ){
    $(this).toggleClass('open')
    $('#navbarToggleExternalContent').toggleClass('show')
  });

  $('.seach svg, .search p').on('click', function ( ){
    $('.search').toggleClass('active');
  });

  $('.search-bar svg').on('click', function ( ){
    $('.search').removeClass('active');
  });

  $('#mainNavigationMobile .menu-item-has-children').on('click', function (event) {
    var clickedElement = $(this);
    var isActive = clickedElement.hasClass('open');

    if (isActive) {
        clickedElement.removeClass('open');
    } else {
        clickedElement.addClass('open');

        // If it's a link, prevent the default behavior
        if (clickedElement.is('a')) {
            event.preventDefault();
        }
    }
  });


  $('.mobile-rows').on('click', function ( ){
    var clickedElement = $(this);
    var targetElements = $('.mobile-rows');

    var isActive = clickedElement.hasClass('open');

    targetElements.removeClass('open');

    if (!isActive) {
      clickedElement.addClass('open');
    }
  });

  $('.slider').slick({
    arrows: false,
    dots: false,
    centerMode: true,
    centerPadding: '15%',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendArrows: ".navigation",
    asNavFor: '.image-slider-number',
    responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 1,
        centerPadding: '0%',
      },
    },
    ]
  });
  $('.image-slider-number').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendArrows: ".navigation",
    fade: true,
    draggable: false,
    asNavFor: '.slider'
  });

  $('.hero-slider').slick({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendArrows: ".home-navigation",
    asNavFor: '.home-slider-number',
  });
  $('.home-slider-number').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendArrows: ".home-navigation",
    fade: true,
    draggable: false,
    asNavFor: '.hero-slider'
  });

  $('.plot-slider').slick({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    appendArrows: ".plot-navigation",
    asNavFor: '.plot-slider-number',
    responsive: [
    {
      breakpoint: 995,
      settings: "unslick"
    },
    ]
  });
  $('.plot-slider-number').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendArrows: ".plot-navigation",
    fade: true,
    draggable: false,
    asNavFor: '.plot-slider'
  });
  var sliderContainers = $('.slider-wrapper');
  sliderContainers.each(function() {
    var containerId = $(this).attr('id');
    var slider = $('#' + containerId + ' .flexi-slider');
    var navigation = $('#' + containerId + ' .flexi-navigation');
    var numbers = $('#' + containerId + ' .flexi-slider-number');
    $(slider).slick({
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      appendArrows: navigation,
      asNavFor: numbers,
    });
    $(numbers).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      appendArrows: navigation,
      fade: true,
      draggable: false,
      asNavFor: slider
    });
  });

  $('#playaboutvid').on('click', function(){
    $(this).hide();
    $('#mainVideo')[0].play();
    $('#mainVideo').attr('controls', true);
  });

  $('.accordion-button').on('click', function(){
    var value = $(this).data("tab")
    var image = $('.accordion-image')
    if($(image).hasClass('show')){
      $(image).removeClass('show');
    }
    $('.accordion-image[data-tab=' + value + ']').addClass('show');
  });

  $('.view-swap').children().on('click', function(){
    $('.view-swap').children().removeClass('active');
    $(this).addClass('active');
    var id = $(this).attr('id');
    if(id === 'list'){
      $('.list').show();
      $('.map-container').hide();
    } else if(id === 'map') {
      $('.map-container').show();
      $('.list').hide();
    }
  });

  // Add a variable to keep track of the currently active plot section
  var activePlotSection = null;

  $('.show-plots').on('click', function() {
    var development = $(this).attr('id');
    var plots = $('.plots-section');

    if (activePlotSection === development) {
        plots.filter('#' + development).slideUp();
        activePlotSection = null;
        return;
    }

    if (activePlotSection) {
        plots.filter('#' + activePlotSection).slideUp();
    }

    plots.filter('#' + development).slideDown();
    activePlotSection = development;
    setTimeout(()=>{
      $('.plot-slider-'+development).slick({
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        appendArrows: "#plot-navigation-"+development,
        asNavFor: '.plot-slider-number-'+development,
        responsive: [
        {
          breakpoint: 995,
          settings: "unslick"
        },
        ]
      });
      $('.plot-slider-number-'+development).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        appendArrows: "#plot-navigation-"+development,
        fade: true,
        draggable: false,
        asNavFor: '.plot-slider-'+development
      });
    }, 1000)
  });



  //Sitemap Zoom and Drag Functionality
  let zoomScale = 1;
  let isDragging = false;
  let startDragX = 0;
  let startDragY = 0;
  let prevTranslateX = 0;
  let prevTranslateY = 0;

  const zoomIn = () => {
    zoomScale += 0.1;
    applyTransform();
  };

  const zoomOut = () => {
    zoomScale -= 0.1;
    applyTransform();
  };

  const applyTransform = () => {
    const $zoomImage = $('#zoom-image');
    $zoomImage.css('transform', `scale(${zoomScale}) translate(${prevTranslateX}px, ${prevTranslateY}px)`);
  };

  $('#zoom-in-btn').on('click', zoomIn);
  $('#zoom-out-btn').on('click', zoomOut);

  $(document).on('mousedown', '#zoom-image', (event) => {
    event.preventDefault();
    isDragging = true;
    startDragX = event.clientX;
    startDragY = event.clientY;
    $('#zoom-image').addClass('dragging');
    const transform = $('#zoom-image').css('transform');
    prevTranslateX = parseFloat(transform.split(',')[4]);
    prevTranslateY = parseFloat(transform.split(',')[5]);
  });

  $(document).on('mousemove', (event) => {
    if (isDragging) {
      const $zoomImage = $('#zoom-image');
      const deltaX = event.clientX - startDragX;
      const deltaY = event.clientY - startDragY;
      const translateX = prevTranslateX + deltaX;
      const translateY = prevTranslateY + deltaY;
      $zoomImage.css('transform', `scale(${zoomScale}) translate(${translateX}px, ${translateY}px)`);
    }
  });

  $(document).on('mouseup', () => {
    isDragging = false;
    $('#zoom-image').removeClass('dragging');
  });

  $('.bedroom-selector').on('change', function() {
    $('.bedroom-selector .selected').removeClass('selected');
    $(this).find(":selected").addClass('selected');
  });

  $('.price-selector').on('change', function() {
    $('.price-selector .selected').removeClass('selected');
    $(this).find(":selected").addClass('selected');
  });

  $('.property-selector').on('change', function() {
    $('.property-selector .selected').removeClass('selected');
    $(this).find(":selected").addClass('selected');
  });

  $('.development-selector').on('change', function() {
    $('.development-selector .selected').removeClass('selected');
    $(this).find(":selected").addClass('selected');
  });

  $('.sort-by').on('change', function() {
    $('.sort-by .selected').removeClass('selected');
    $(this).find(":selected").addClass('selected');
  });

  let currentPage = 1;

  $('#load-more').on('click', function(e) {
    e.preventDefault();
    currentPage++; // Do currentPage + 1, because we want to load the next page
    
    var bedrooms = $('.bedroom-selector .selected').val();
    var price = $('.price-selector .selected').val();
    var property = $('.property-selector .selected').val();
    $.ajax({
        type: 'POST',
        url: '/wp-admin/admin-ajax.php',
        dataType: 'json',
        data: {
        action: 'focus_load_more',
        paged: currentPage,
        bedroomsfilter: bedrooms,
        pricefilter: price,
        propertyfilter: property
        },
        success: function (res) {
          console.log(currentPage);
          console.log(res.data.max);
          console.log("Max Pages: ", res.data.max);
          if (currentPage >= res.data.max) {
            $('.btn__wrapper').hide();
          }
          $('#plotsList').append(res.data.html);
        }
    });
  });

  $('#location-submit').on('click', function() {
    currentPage = 1;
    // $('#project-loader').show();
    var bedrooms = $('.bedroom-selector .selected').val();
    var price = $('.price-selector .selected').val();
    var property = $('.property-selector .selected').val();
    var developmentId = $('#location-submit').data('development-id');
    if(bedrooms == 'any'){
      bedrooms = null
    } else {
      bedrooms = bedrooms
    }
    if(price == 'any'){
      price = null
    } else {
      price = price
    }
    if(property == 'any'){
      property = null
    } else {
      property = property
    }
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: { action: 'focus_plot_filter', bedroomsfilter: bedrooms, pricefilter: price, propertyfilter: property, developmentId: developmentId, },
      type: 'POST',
      dataType: 'json',
      success: function(res) {
        if (res.data.max === 1) {
          $('.btn__wrapper').hide();
        }
        $('#plotsList').empty();
        $('#plotsList').append(res.data.html);
      }
    });
  });

  $('.sort-by').on('change', function() {
    currentPage = 1;
    var bedrooms = $('.bedroom-selector .selected').val();
    var price = $('.price-selector .selected').val();
    var property = $('.property-selector .selected').val();
    var developmentId = $('#location-submit').data('development-id');
    var selectedSort = $('.sort-by .selected').val();
    var selectedSize = null;
    var selectedPrice = null;
    if(bedrooms == 'any'){
      bedrooms = null
    } else {
      bedrooms = bedrooms
    }
    if(price == 'any'){
      price = null
    } else {
      price = price
    }
    if(property == 'any'){
      property = null
    } else {
      property = property
    }
    if(selectedSort == 'any'){
      selectedSize = null;
      selectedPrice = null;
    } else if(selectedSort == 'size-desc') {
      selectedSize = 'DESC';
      selectedPrice = null;
    } else if(selectedSort == 'size-asc') {
      selectedSize = 'ASC';
      selectedPrice = null;
    } else if(selectedSort == 'price-desc') {
      selectedSize = null;
      selectedPrice = 'DESC';
    } else if(selectedSort == 'price-asc') {
      selectedSize = null;
      selectedPrice = 'ASC';
    }
    console.log(selectedSort);
    console.log(selectedSize);
    console.log(selectedPrice);
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: { action: 'focus_sort_by', bedroomsfilter: bedrooms, pricefilter: price, propertyfilter: property, developmentId: developmentId, sizeSelected: selectedSize, priceSelected: selectedPrice },
      type: 'POST',
      dataType: 'json',
      success: function(res) {
        $('.btn__wrapper').hide();
        $('#plotsList').empty();
        $('#plotsList').append(res.data.html);
      }
    });
  });

  $('.location-clear-btn').on('click', function() {
    currentPage = 1;
    $('.btn__wrapper').show();
    $('.bedroom-selector').val('any').change();
    $('.price-selector').val('any').change();
    $('.property-selector').val('any').change();
    var developmentId = $('#location-submit').data('development-id');
    var bedrooms = 'any';
    var price = 'any';
    var property = 'any'
    if(bedrooms == 'any'){
      bedrooms = 0
    } else {
      bedrooms = bedrooms
    }
    if(price == 'any'){
      price = null
    } else {
      price = price
    }
    if(property == 'any'){
      property = null
    } else {
      property = property
    }
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: { action: 'focus_filter', bedroomsfilter: bedrooms, pricefilter: price, propertyfilter: property, developmentId: developmentId,},
      type: 'POST',
      dataType: 'json',
      success: function(res) {
        $('#plotsList').empty();
        $('#plotsList').append(res.data.html);
      }
    });
  });

  $('.clear-btn').on('click', function() {
    currentPage = 1;
    $('.btn__wrapper').show();
    $('.bedroom-selector').val('any').change();
    $('.price-selector').val('any').change();
    $('.property-selector').val('any').change();
    var bedrooms = 'any';
    var price = 'any';
    var property = 'any'
    if(bedrooms == 'any'){
      bedrooms = 0
    } else {
      bedrooms = bedrooms
    }
    if(price == 'any'){
      price = null
    } else {
      price = price
    }
    if(property == 'any'){
      property = null
    } else {
      property = property
    }
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: { action: 'focus_filter', bedroomsfilter: bedrooms, pricefilter: price, propertyfilter: property},
      type: 'POST',
      dataType: 'json',
      success: function(res) {
        $('#plotsList').empty();
        $('#plotsList').append(res.data.html);
      }
    });
  });

  $('.filters-heading').on('click', function() {
    $('.filters').toggleClass('open');
    $('.filters-clear').toggleClass('open')
  });

  $('#development_nav').on('change', function() {
    var url = $(this).find(":selected").val();
    window.location.href = url;
  });

  $('#archive-submit').on('click', function() {
    currentPage = 1;
    var development = parseInt($('.development-selector .selected').val(), 10);
    var bedrooms = $('.bedroom-selector .selected').val();
    var price = $('.price-selector .selected').val();
    var property = $('.property-selector .selected').val();

    if (development == 'any') {
      development = null;
    }

    if (bedrooms == 'any') {
        bedrooms = null;
    }

    if (price == 'any') {
        price = null;
    }

    if (property == 'any') {
        property = null;
    }

    // Loop through each development wrapper
    $('.development-wrapper').each(function() {
        var developmentId = $(this).data('development-id');
        var $developmentWrapper = $(this); // Store a reference to $(this)
        developmentId = parseInt(developmentId, 10);
        if (development) {
          if (development === developmentId) {
              $developmentWrapper.show();
          } else {
              $developmentWrapper.hide();
          }
        }
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: {
                action: 'focus_filter',
                bedroomsfilter: bedrooms,
                pricefilter: price,
                propertyfilter: property,
                developmentId: developmentId,
            },
            type: 'POST',
            dataType: 'json',
            success: function(res) {
              var $developmentWrapper = $('[data-development-id="' + developmentId + '"]');
              if (res.data.max === 1) {
                  $developmentWrapper.find('.btn__wrapper').hide();
              }
              $developmentWrapper.find('.plots-list').empty().append(res.data.html);
              $developmentWrapper.find('.plots-section').show(); // Show the correct development's plots section
            }
        });
        $('.plots-section').show();
    });
  });

  function clearFilterCriteria() {
    localStorage.removeItem('filterCriteria');
  }

  function handleFilterSubmission() {
    // Retrieve filter criteria
    var development = parseInt($('.development-selector .selected').val(), 10);
    var bedrooms = $('.bedroom-selector .selected').val();
    var price = $('.price-selector .selected').val();
    var property = $('.property-selector .selected').val();

    if (development == 'any') {
        development = null;
    }

    if (bedrooms == 'any') {
        bedrooms = null;
    }

    if (price == 'any') {
        price = null;
    }

    if (property == 'any') {
        property = null;
    }

    // Store the filter criteria in local storage
    var filterCriteria = {
        development: development,
        bedrooms: bedrooms,
        price: price,
        property: property
    };

    localStorage.setItem('filterCriteria', JSON.stringify(filterCriteria));

    // Redirect to the "Find Your Home" page
    window.location.href = '/find-your-home';
  }

  function applyFilterCriteria() {
    // Retrieve filter criteria from local storage
    var storedFilterCriteria = localStorage.getItem('filterCriteria');
    var filterCriteria = JSON.parse(storedFilterCriteria);

    if (filterCriteria) {
        // Use the retrieved filter criteria for filtering and displaying results
        var development = filterCriteria.development;
        var bedrooms = filterCriteria.bedrooms;
        var price = filterCriteria.price;
        var property = filterCriteria.property;

        // Perform filtering and displaying of results, similar to your previous code
        currentPage = 1;

        // Loop through each development wrapper
        $('.development-wrapper').each(function() {
            var developmentId = $(this).data('development-id');
            var $developmentWrapper = $(this); // Store a reference to $(this)
            developmentId = parseInt(developmentId, 10);

            if (development === null || development === developmentId) {
                $developmentWrapper.show();
            } else {
                $developmentWrapper.hide();
            }

            // Perform AJAX request for filtering and displaying results, similar to your previous code
            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                data: {
                    action: 'focus_filter',
                    bedroomsfilter: bedrooms,
                    pricefilter: price,
                    propertyfilter: property,
                    developmentId: developmentId,
                },
                type: 'POST',
                dataType: 'json',
                success: function(res) {
                    var $developmentWrapper = $('[data-development-id="' + developmentId + '"]');
                    if (res.data.max === 1) {
                        $developmentWrapper.find('.btn__wrapper').hide();
                    }
                    $developmentWrapper.find('.plots-list').empty().append(res.data.html);
                    $developmentWrapper.find('.plots-section').show(); // Show the correct development's plots section
                }
            });

            $('.plots-section').show();
        });
    }
  }

  $(document).ready(function() {
    applyFilterCriteria();
    clearFilterCriteria();
  });

  // Event listener for filter button click
  $('#home-submit').on('click', function() {
      handleFilterSubmission();
  });

  $('.clear-btn-home').on('click', function() {
    currentPage = 1;
    var bedrooms = null;
    var price = null;
    var property = null;

    $('.development-selector').val('any');
    $('.bedroom-selector').val('any');
    $('.price-selector').val('any');
    $('.property-selector').val('any');

    // Loop through each development wrapper
    $('.development-wrapper').each(function() {
        $(this).show();
        var developmentId = $(this).data('development-id');
        var $developmentWrapper = $(this); // Store a reference to $(this)
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: {
                action: 'focus_filter',
                bedroomsfilter: bedrooms,
                pricefilter: price,
                propertyfilter: property,
                developmentId: developmentId,
            },
            type: 'POST',
            dataType: 'json',
            success: function(res) {
                if (res.data.max === 1) {
                    $developmentWrapper.find('.btn__wrapper').hide(); // Use the reference
                }
                $developmentWrapper.find('.plots-list').empty().append(res.data.html); // Use the reference
            }
        });
        $('.plots-section').hide();
    });
  });


  $('.news-clear-btn').on('click', function() {
    newsCurrentPage = 1;
    var categoryID = 'all';
    $('.btn__wrapper').show();
    $('.filter-button input[type=radio]').prop('checked', false)
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: { action: 'news_focus_filter', categoryfilter: categoryID},
      type: 'POST',
      dataType: 'json',
      success: function(res) {
        if (res.data.max === 1) {
          $('.btn__wrapper').hide();
        }
        $('.news-items').empty();
        $('.news-items').append(res.data.html);
      }
    });
  });

  $('.filter-button input[type=radio]').on('click', function() {
    newsCurrentPage = 1;
    $('.btn__wrapper').show();
    var categoryID = $(this).data('category');
    var category = $(this).data('name');
    window.history.replaceState(null, null, "#" + category);
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        data: { action: 'news_focus_filter', categoryfilter: categoryID },
        type: 'POST',
        dataType: 'json',
        success: function(res) {
          if (res.data.max === 1) {
            $('.btn__wrapper').hide();
          }
          $('.news-items').empty();
          $('.news-items').append(res.data.html);
        }
    });
  });

  $('.mobile-dropdown-filter select').on('change', function() {
    newsCurrentPage = 1;
    $('.btn__wrapper').show();
    var categoryID = $(this).val();
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        data: { action: 'news_focus_filter', categoryfilter: categoryID },
        type: 'POST',
        dataType: 'json',
        success: function(res) {
          if (res.data.max === 1) {
            $('.btn__wrapper').hide();
          }
          $('.news-items').empty();
          $('.news-items').append(res.data.html);
        }
    });
  });

});
